import jQuery from 'jquery';
import LazyLoad from 'vanilla-lazyload';
import MicroModal from 'micromodal';
import Chocolat from 'chocolat';
import 'slick-carousel';

(($) => {
  const lazyLoad = new LazyLoad({
    elements_selector: 'img.lazy',
  });
  if (lazyLoad) {
    lazyLoad.update();
  }

  const bodyObserver = new IntersectionObserver((cb) => {
    if (cb[0].isIntersecting) {
      document.body.classList.remove('entered');
    } else {
      document.body.classList.add('entered');
    }
  });
  bodyObserver.observe(document.querySelector('.body-observer'));

  MicroModal.init();

  const toggleMobileCounter = ($obj) => {
    const innerH = window.innerHeight;
    const innerW = window.innerWidth;
    if (innerH < 590 && innerW < 768) {
      $obj.addClass('hidden');
    }
    if (innerH >= 590 && innerW < 768) {
      $obj.removeClass('hidden');
    }
  };

  /*-----------------------------------
    * hero slider
    ----------------------------------*/
  const $heroContainer = $('.hero-images-container');
  const $heroImages = $('img', $heroContainer);

  $('.hero-images-container').slick({
    slidesToShow: 1,
    infinite: true,
    speed: 3000,
    fade: true,
    cssEase: 'ease-in-out',
    autoplay: true,
    autoplaySpeed: '12000',
    arrows: false,
    swipe: false,
    lazyLoad: 'ondemand',
  })
    .on('beforeChange', (event, slick, current, next) => {
      $($heroImages[next]).addClass('trans');
      setTimeout(() => {
        $($heroImages[current]).removeClass('trans');
      }, 2000);
    });

  /*-----------------------------------
     * counter
     ----------------------------------*/
  const $opening = $('.counter');
  const $dayCounter = $('.days span', $opening);
  const $hoursCounter = $('.hours span', $opening);
  const $minutesCounter = $('.minutes span', $opening);
  const $counterMobile = $('.counter-mobile');
  const $daysMobile = $('.days', $counterMobile);
  const $hoursMobile = $('.hours', $counterMobile);
  const $minutesMobile = $('.minutes', $counterMobile);
  const countDownDate = new Date($opening.data('opening')).getTime();
  toggleMobileCounter($counterMobile);
  const displayDistance = () => {
    const distance = countDownDate - new Date().getTime();
    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    $dayCounter.text(days);
    $daysMobile.text(days);
    $hoursCounter.text(hours);
    $hoursMobile.text(hours);
    $minutesCounter.text(minutes);
    $minutesMobile.text(minutes);
  };
  setInterval(() => {
    displayDistance();
  }, 1000);

  /*-----------------------------------
   * gallery
   ----------------------------------*/
  // $.fn.Chocolat = Chocolat;
  // $('#gallery').Chocolat();
  Chocolat(document.querySelectorAll('#gallery .chocolat-image'), {});

  /*-----------------------------------
   * play promo (program) video
   ----------------------------------*/
  $('.promo-video').on('click', '.play-btn', (ev) => {
    const $parent = $(ev.delegateTarget);
    const $iframe = $($parent.data('iframe'));
    $parent.append($iframe);
  });

  /*-----------------------------------
   * newsletter (mailchimp) forms
   ----------------------------------*/
  const $nlForm = $('form.newsletter');
  const $input = $('input#email', $nlForm);
  const $submitBtn = $('button[type=submit]', $nlForm);
  const $nlRegError = $('.nl-reg-error', $nlForm);
  const $nlRegSuccess = $('.nl-reg-success');
  $nlForm.on('submit', function (ev) { // eslint-disable-line
    ev.preventDefault();
    $submitBtn.prop('disabled', true);
    $input.prop('disabled', true);
    $nlRegError.addClass('hidden');

    $.post({
      url: '/',
      dataType: 'json',
      data: $nlForm.serialize(),
      success: (response) => {
        $submitBtn.prop('disabled', false);
        $input.prop('disabled', false);
        if (response.success) {
          $nlForm.slideUp();
          $nlRegSuccess.slideDown();
        } else {
          if (response.errorCode === '1000') {
            $nlRegError.text('Deine E-Mail-Einstellungen sind ungültig.');
          }
          if (response.errorCode !== '1000') {
            $nlRegError.text('Ein Fehler ist augetreten.');
          }
          $nlRegError.removeClass('hidden');
        }
      },
      error: (error) => {
        console.error('NL:', error);
        $submitBtn.prop('disabled', false);
        $input.prop('disabled', false);
        $nlRegError.text('Ein Fehler ist augetreten.');
        $nlRegError.removeClass('hidden');
      },
    });
  });

  $(window).on('resize', () => {
    toggleMobileCounter($counterMobile);
  });
})(jQuery);
