import jQuery from 'jquery';
import VimeoPlayer from '@vimeo/player';

(($) => {
  /*-----------------------------------
   * players
   ----------------------------------*/
  const ytPlayers = [];
  const vimeoPlayers = [];
  const scPlayers = [];

  function pauseYTVideo(id = false) {
    if (id) {
      id = id.split('-')[1]; //eslint-disable-line
    }
    Object.keys(ytPlayers).forEach((key) => {
      if (id) {
        if (key !== id) {
          ytPlayers[key].pauseVideo();
        }
      } else {
        ytPlayers[key].pauseVideo();
      }
    });
  }

  function pauseVimeoVideo() {
    Object.keys(vimeoPlayers).forEach((key) => {
      vimeoPlayers[key].pause();
    });
  }

  function pauseSoundClodeAudio() {
    Object.keys(scPlayers).forEach((key) => {
      scPlayers[key].pause();
    });
  }

  const onYTPlayerStateChange = (event) => {
    if (event.data === 1) {
      pauseYTVideo(event.target.a.id);
      pauseVimeoVideo();
      pauseSoundClodeAudio();
    }
  };

  const initVimeoPlayer = (id) => {
    vimeoPlayers[id] = new VimeoPlayer(`v-${id}`, {
      id,
    });
    vimeoPlayers[id].on('play', () => {
      pauseYTVideo();
      pauseSoundClodeAudio();
    });
  };

  const initYTPlayer = (id) => {
    const { YT } = window;
    ytPlayers[id] = new YT.Player(`yt-${id}`, {
      videoId: id,
      host: `${window.location.protocol}//www.youtube.com`,
      events: {
        onStateChange: onYTPlayerStateChange,
      },
    });
  };

  const initSCPayer = (track) => {
    const { SC } = window;
    const iframe = document.getElementById(`sc-${track}`);
    const widget = SC.Widget(iframe);
    scPlayers[track] = widget;
    widget.bind(SC.Widget.Events.READY, () => {
      widget.bind(SC.Widget.Events.PLAY, () => {
        pauseYTVideo();
        pauseVimeoVideo();
      });
    });
  };

  $('.embed').on('click', '.play-btn', (ev) => {
    const dataSet = ev.delegateTarget.dataset;
    const { classList } = ev.delegateTarget;
    if (classList.contains('vimeo')) {
      initVimeoPlayer(dataSet.embedId);
    }
    if (classList.contains('you-tube')) {
      initYTPlayer(dataSet.embedId);
    }
    if (classList.contains('sound-cloud')) {
      const $iframe = $(dataSet.iframe);
      $(ev.delegateTarget).append($iframe);
      setTimeout(() => {
        initSCPayer(dataSet.embedId);
      }, 10);
    }
  });

  /*-----------------------------------
   * accordion events
   ----------------------------------*/
  const mutePlayersInAccordion = ($nodes) => {
    $nodes.each((i, elem) => {
      const { embedId } = elem.dataset;
      if (elem.classList.contains('vimeo') && vimeoPlayers[embedId] !== undefined) {
        vimeoPlayers[embedId].pause();
      }
      if (elem.classList.contains('you-tube') && ytPlayers[embedId] !== undefined) {
        ytPlayers[embedId].pauseVideo();
      }
      if (elem.classList.contains('sound-cloud') && scPlayers[embedId] !== undefined) {
        scPlayers[embedId].pause();
      }
    });
  };
  const $eventList = $('.event-list');
  // toggle events of the month
  $eventList.on('click', '.accordion-month, .accordion-year', function (ev) {// eslint-disable-line
    const $this = $(this);
    $this.toggleClass('open');
    $this.next().slideToggle();
    if (!$this.hasClass('open')) {
      mutePlayersInAccordion($('.embed', $this.next()));
    }
  });
  // toggle event (artist) item
  $eventList.on('click', '.toggle-btn', function () { // eslint-disable-line
    const $parent = $(this).parent();
    $parent.toggleClass('open');
    const $accordionContent = $parent.next();
    if (!$parent.hasClass('open')) {
      mutePlayersInAccordion($('.embed', $accordionContent));
    }
    $accordionContent.slideToggle();
  });
})(jQuery);
