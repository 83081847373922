import jQuery from 'jquery';

import 'waypoints/lib/noframework.waypoints';

(($) => {
  const $mainNavs = document.querySelectorAll('[data-scroll-to]');

  function removeActiveClass() {
    $mainNavs.forEach((el) => {
      el.classList.remove('active');
    });
  }

  function toggleActiveClass(target) {
    removeActiveClass();
    Array.from($mainNavs)
      .filter((sec) => sec.dataset.scrollTo === target)
      .forEach((elem) => {
        elem.classList.add('active');
      });
  }

  function scrollToTarget() {
    const target = document.querySelector(`[data-scroll-target=${this.dataset.scrollTo}]`);
    window.scrollTo({
      left: 0,
      top: target.offsetTop - 80,
      behavior: 'smooth',
    });
  }

  const waypoits = [
    { target: 'top', offset: -50 },
    { target: 'program', offset: 80 },
    { target: 'bar', offset: 80 },
    { target: 'about', offset: 0 },
    { target: 'contact', offset: 100 },
  ];

  if ($mainNavs.length > 0) {
    waypoits.forEach((obj) => {
      const wp = new Waypoint({ // eslint-disable-line
        element: document.querySelector(`[data-scroll-target=${obj.target}]`),
        handler() {
          toggleActiveClass(obj.target);
        },
        offset: obj.offset,
      });
    });
    $mainNavs.forEach((el) => el.addEventListener('click', scrollToTarget));
  }

  const $mobileNav = $('.mobile-nav');
  $mobileNav.on('click', '.nav-icon-toggle', () => {
    $mobileNav.toggleClass('mobile-nav-open');
  });
  $mobileNav.on('click', 'nav li', () => {
    setTimeout(() => {
      $mobileNav.removeClass('mobile-nav-open');
    }, 500);
  });
})(jQuery);
